import React from 'react'
import {Link} from 'gatsby'

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="#" >
                        AI & ML Development
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        Data Analytics
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        Data Science
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        Artificial Intelligence
                    </Link>
                </li>
                <li>
                    <Link to="#">
                        Data Visualization
                    </Link>
                </li>
            </ul>


            <ul className="services-list">
                <li>
                    <Link to="#" >
                    User experience (UX/UI)
                    </Link>
                </li>
                <li>
                    <Link to="#">
                            Mobile and Web Development
                    </Link>
                </li>
                <li>
                    <Link to="#">
                         QA and Testing 
                    </Link>
                </li>
                <li>
                    <Link to="#">
                    DevOps 
                    </Link>
                </li>
                <li>
                    <Link to="#">
                    Security 
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default ServiceSidebar