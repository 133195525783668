import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContent from '../components/ServiceDetails/ServiceDetailsContent'
import TagsForAll from '../components/Common/TagsForAll'
import { Helmet } from 'react-helmet'

 
const Details = () => {
    return (
        <Layout>
        <TagsForAll pageTitle="Services Details" />
        <Helmet>
            <meta name='robots' content='index, follow' />
            <link rel="canonical" href="https://zaavia.net/service-details" />
        </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Service Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Details" 
            />
            <ServiceDetailsContent />
            <Footer />
        </Layout>
    );
}

export default Details